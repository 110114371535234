import { NAME as CardName } from '@spa-ec/components/Card/Card.theme';
import { NAME as PromotionsName } from '@spa-ec/components/Header/HeaderPromotions/Promotions.theme';
import { NAME as OfferBannerName } from '@spa-ec/components/PersonalizedCockpit/OfferBanner/OfferBanner.theme';
import { NAME as ReplacementPanelName, } from '@spa-ec/components/ProductDetails/ReplacementPanel/ReplacementPanel.theme';
import { NAME as MobileDropDownName, } from '@spa-ec/components/Header/HeaderMobileDropDown/MobileDropDown.theme';
const cardTheme = {
    showEnKrBackground: true,
};
const promotionsTheme = {
    promotionImagePath: '/_ui/dist/theme-inkclub/images/icons/bullhorn_ebk_inkclub.svg',
};
const offerBannerTheme = {
    enabledOfferBanner: true,
};
const replacementPanelTheme = {
    emvWarrantyImagePath: '/images/icon-Garanti-small.png',
};
const mobileDropDownTheme = {
    contactUsUrl: '/contact_us',
};
export default {
    [CardName]: cardTheme,
    [PromotionsName]: promotionsTheme,
    [OfferBannerName]: offerBannerTheme,
    [ReplacementPanelName]: replacementPanelTheme,
    [MobileDropDownName]: mobileDropDownTheme,
};
